import React from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { hostConfig } from './config';
import { useNavigate } from 'react-router-dom';

// Create a secure API client with API key
const secureClient = axios.create({
  baseURL: hostConfig.API_BASE,
  headers: {
    'Content-Type': 'application/json',
    'X-API-Key': hostConfig.API_KEY || 'dev-api-key-123'
  },
  withCredentials: true // Enables sending cookies in cross-domain requests
});

// Add authorization header to all requests if token exists
secureClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('patientToken') || localStorage.getItem('therapistToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const LogoutButton = ({ userType = 'patient', variant = 'contained', className, sx }) => {
  const navigate = useNavigate();
  
  const handleLogout = async () => {
    try {
      // Determine the redirect page based on user type
      const redirectPath = userType === 'therapist' ? '/therapistlogin' : '/patientlogin';
      
      // Call secure logout endpoint
      await secureClient.post('/api/auth/logout');
      
      // Clear all tokens from localStorage
      localStorage.removeItem('patientToken');
      localStorage.removeItem('therapistToken');
      localStorage.removeItem('userRole');
      localStorage.removeItem('userUuid');
      
      // Basic app state cleanup
      localStorage.removeItem('selectedLanguage');
      
      // Redirect to the appropriate login page
      navigate(redirectPath);
    } catch (error) {
      console.error('Logout failed:', error);
      
      // Even if the API call fails, still perform client-side logout
      localStorage.clear();
      navigate(userType === 'therapist' ? '/therapistlogin' : '/patientlogin');
    }
  };

  return (
    <Button 
      variant={variant} 
      onClick={handleLogout} 
      className={className}
      sx={sx || {}}
    >
      Logout
    </Button>
  );
};

export default LogoutButton;