import React, { useState } from 'react';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { Language } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <div style={{
      position: 'fixed',
      bottom: '24px',
      right: '24px',
      zIndex: 9999
    }}>
      <div 
        style={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          backgroundColor: '#0A6621',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 4px 15px rgba(0,0,0,0.3)',
          border: '2px solid white',
          cursor: 'pointer',
          transition: 'all 0.2s ease'
        }}
        onClick={handleClick}
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Language style={{ fontSize: '28px' }} />
      </div>
      
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{ zIndex: 10000 }}
      >
        <MenuItem onClick={() => changeLanguage('en')} selected={i18n.language === 'en'}>
          <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: i18n.language === 'en' ? 'bold' : 'normal' }}>
              English
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('ja')} selected={i18n.language === 'ja'}>
          <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: i18n.language === 'ja' ? 'bold' : 'normal' }}>
              日本語
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;