import React, { useEffect, useState } from "react";
import { 
  Container, 
  Typography, 
  Button, 
  Box, 
  TextField, 
  Grid, 
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Snackbar,
  Alert
} from '@mui/material';
import { hostConfig } from "../../config.js";
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import TherapistAccountEdit from "./TherapistInfoEdit.js";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessIcon from '@mui/icons-material/Business';
import DescriptionIcon from '@mui/icons-material/Description';

// Create a secure API client with API key and authorization
const secureClient = axios.create({
  baseURL: hostConfig.THERAPIST_API_BASE,
  headers: {
    'Content-Type': 'application/json',
    'X-API-Key': hostConfig.API_KEY || 'dev-api-key-123'
  },
  withCredentials: true // Enables sending cookies in cross-domain requests
});

// Add authorization header to all requests if token exists
secureClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('therapistToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: '10px 20px',
  borderRadius: '8px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const FormField = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const FieldLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
  fontWeight: 500,
}));

const InputField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
}));

const TherapistAccountInformation = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [therapistInfo, setTherapistInfo] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    hourly_rate: '',
    practice: '',
    introduction: '',
    currency: 'JPY'
  });
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  // List of currencies with their symbols
  const currencies = [
    { code: 'USD', symbol: '$', name: 'US Dollar' },
    { code: 'JPY', symbol: '¥', name: 'Japanese Yen' },
    { code: 'GBP', symbol: '£', name: 'British Pound' },
    { code: 'EUR', symbol: '€', name: 'Euro' },
    { code: 'CAD', symbol: 'C$', name: 'Canadian Dollar' },
    { code: 'AUD', symbol: 'A$', name: 'Australian Dollar' },
  ];

  // Get currency symbol based on the selected currency
  const getCurrencySymbol = () => {
    const currencyData = currencies.find(c => c.code === therapistInfo.currency);
    return currencyData ? currencyData.symbol : '$';
  };

  // Handle notification close
  const handleNotificationClose = () => {
    setNotification({...notification, open: false});
  };

  // Format price for storage - convert to smallest unit (cents, yen, etc.)
  const formatPriceForStorage = (price, currency) => {
    if (!price || isNaN(parseFloat(price))) return '';

    // JPY doesn't use decimals
    if (currency === 'JPY') {
      return Math.round(parseFloat(price)).toString();
    }

    // For other currencies, convert to smallest unit (e.g., cents)
    // Multiply by 100 and round to ensure no decimal places
    return Math.round(parseFloat(price) * 100).toString();
  };

  // Format price for display - convert from smallest unit to display format
  const formatPriceForDisplay = (price, currency) => {
    if (!price || isNaN(parseInt(price))) return '';

    // JPY doesn't use decimals
    if (currency === 'JPY') {
      return price.toString();
    }

    // For other currencies, convert from smallest unit (e.g., cents to dollars)
    const numericValue = parseInt(price) / 100;
    return numericValue.toString();
  };

  useEffect(() => {
    async function fetchTherapistInfo() {
      setLoading(true);
      try {
        // Use the secure client with the new API route
        const response = await secureClient.get('/api/protected/therapist/account');
        
        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          const data = response.data[0];
          
          // Format the hourly rate for display
          const formattedHourlyRate = formatPriceForDisplay(data.hourly_rate, data.currency || 'JPY');
          
          setTherapistInfo({
            ...data,
            hourly_rate: formattedHourlyRate,
            currency: data.currency || 'JPY'
          });
        } else if (response.data && !Array.isArray(response.data)) {
          // If response is a single object, not an array
          const data = response.data;
          
          // Format the hourly rate for display
          const formattedHourlyRate = formatPriceForDisplay(data.hourly_rate, data.currency || 'JPY');
          
          setTherapistInfo({
            ...data,
            hourly_rate: formattedHourlyRate,
            currency: data.currency || 'JPY'
          });
        } else {
          setNotification({
            open: true,
            message: 'Failed to load therapist information. Please try again.',
            severity: 'error'
          });
        }
      } catch (error) {
        console.error('Error fetching therapist information:', error);
        setNotification({
          open: true,
          message: `Error loading account information: ${error.response?.data?.message || error.message}`,
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    }
  
    fetchTherapistInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTherapistInfo(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Format price for storage (no decimals, convert to smallest unit)
      const storagePrice = formatPriceForStorage(therapistInfo.hourly_rate, therapistInfo.currency);
      
      // Use the secure client with the new API route
      const response = await secureClient.post('/api/protected/therapist/account/edit', {
        therapist_first_name: therapistInfo.first_name,
        therapist_last_name: therapistInfo.last_name,
        therapist_email: therapistInfo.email,
        therapist_phone_number: therapistInfo.phoneNumber,
        therapist_uuid: therapistInfo.uuid,
        price: storagePrice, // Send price without decimals
        currency: therapistInfo.currency,
        description: therapistInfo.introduction,
        practice: therapistInfo.practice,
      });
      
      if (response.data && response.data.success) {
        // Show success notification
        setNotification({
          open: true,
          message: 'Profile updated successfully!',
          severity: 'success'
        });
      } else {
        // Handle error response
        setNotification({
          open: true,
          message: response.data?.message || 'Failed to update profile',
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('Error updating therapist profile:', error);
      setNotification({
        open: true,
        message: `Error updating profile: ${error.response?.data?.message || error.message}`,
        severity: 'error'
      });
    }
  };

  // Show loading state
  if (loading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <Typography>Loading account information...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="subtitle1" color="primary" sx={{ mb: 1 }}>
          {t('therapistdashboard.accountinformation.yourprofile', 'Your Profile')}
        </Typography>
        
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>
          {t('therapistdashboard.accountinformation.title', 'Account Information')}
        </Typography>
        
        <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
          {t('therapistdashboard.accountinformation.subtitle', 'Update your personal and professional details')}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormField>
                <FieldLabel>
                  <PersonIcon sx={{ mr: 1, fontSize: 20 }} />
                  {t('therapistdashboard.accountinformation.name', 'First Name')}
                </FieldLabel>
                <InputField
                  placeholder={t('therapistdashboard.accountinformation.name', 'Enter your first name')}
                  name="first_name"
                  value={therapistInfo.first_name || ''}
                  onChange={handleChange}
                  variant="outlined"
                />
              </FormField>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <FormField>
                <FieldLabel>
                  <PersonIcon sx={{ mr: 1, fontSize: 20 }} />
                  {t('therapistdashboard.accountinformation.lastname', 'Last Name')}
                </FieldLabel>
                <InputField
                  placeholder={t('therapistdashboard.accountinformation.lastname', 'Enter your last name')}
                  name="last_name"
                  value={therapistInfo.last_name || ''}
                  onChange={handleChange}
                  variant="outlined"
                />
              </FormField>
            </Grid>
            
            <Grid item xs={12}>
              <FormField>
                <FieldLabel>
                  <EmailIcon sx={{ mr: 1, fontSize: 20 }} />
                  {t('therapistdashboard.accountinformation.email', 'Email Address')}
                </FieldLabel>
                <InputField
                  placeholder={t('therapistdashboard.accountinformation.email', 'Enter your email address')}
                  name="email"
                  value={therapistInfo.email || ''}
                  onChange={handleChange}
                  variant="outlined"
                  type="email"
                />
              </FormField>
            </Grid>
            
            <Grid item xs={12}>
              <FormField>
                <FieldLabel>
                  <AttachMoneyIcon sx={{ mr: 1, fontSize: 20 }} />
                  {t('therapistdashboard.accountinformation.price', 'Hourly Rate')}
                </FieldLabel>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl 
                    variant="outlined" 
                    sx={{ 
                      width: '120px', 
                      mr: 2,
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        height: '56px'
                      }
                    }}
                  >
                    <Select
                      name="currency"
                      value={therapistInfo.currency || 'JPY'}
                      onChange={handleChange}
                      sx={{ borderRadius: '8px' }}
                      displayEmpty
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.symbol} {option.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  
                  <InputField
                    placeholder={therapistInfo.currency === 'JPY' ? "1000" : "10.00"}
                    name="hourly_rate"
                    value={therapistInfo.hourly_rate || ''}
                    onChange={handleChange}
                    variant="outlined"
                    type="number"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {getCurrencySymbol()}
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </FormField>
            </Grid>
            
            <Grid item xs={12}>
              <FormField>
                <FieldLabel>
                  <BusinessIcon sx={{ mr: 1, fontSize: 20 }} />
                  {t('therapistdashboard.accountinformation.practice', 'Practice Area')}
                </FieldLabel>
                <InputField
                  placeholder={t('therapistdashboard.accountinformation.practice', 'Enter your area of practice')}
                  name="practice"
                  value={therapistInfo.practice || ''}
                  onChange={handleChange}
                  variant="outlined"
                />
              </FormField>
            </Grid>
            
            <Grid item xs={12}>
              <FormField>
                <FieldLabel>
                  <DescriptionIcon sx={{ mr: 1, fontSize: 20 }} />
                  {t('therapistdashboard.accountinformation.description', 'Description')}
                </FieldLabel>
                <InputField
                  placeholder={t('therapistdashboard.accountinformation.description', 'Write a brief description about yourself')}
                  name="introduction"
                  value={therapistInfo.introduction || ''}
                  onChange={handleChange}
                  variant="outlined"
                  multiline
                  rows={5}
                />
              </FormField>
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <SaveButton 
              type="submit"
              startIcon={<span>📋</span>}
            >
              {t('therapistdashboard.accountinformation.saveButton', 'Save Changes')}
            </SaveButton>
          </Box>
        </form>
      </Box>

      {/* Notifications */}
      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleNotificationClose} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TherapistAccountInformation;