import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from "./App";
import AgoraRTC from "agora-rtc-sdk-ng";
import { AgoraRTCProvider } from "agora-rtc-react";
import i18n from "./i18n";
import axios from 'axios';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

// Development access setup
const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';
const DEV_ACCESS_TOKEN = process.env.REACT_APP_DEV_ACCESS_TOKEN || '1234abcd-ef56-7890-gh12-ijklmnopqrst'; // Use the same UUID as in server.js

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

async function devLogin() {
  if (process.env.NODE_ENV !== 'development') {
    console.log('Dev login is only available in development mode');
    return false;
  }

  try {
    const response = await api.post('https://www.ascreforme.com/dev-login', { token: DEV_ACCESS_TOKEN });
    console.log('Development session established:', response.data.message);
    return true;
  } catch (error) {
    console.error('Failed to establish development session:', error.message);
    return false;
  }
}

export async function makeDevRequest(endpoint, method = 'get', data = null) {
  if (process.env.NODE_ENV !== 'development') {
    throw new Error('Dev requests are only available in development mode');
  }

  try {
    const response = await api({
      method,
      url: endpoint,
      data,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      // Session might have expired, try to login again
      const loginSuccess = await devLogin();
      if (loginSuccess) {
        // Retry the request
        return makeDevRequest(endpoint, method, data);
      }
    }
    console.error('Error making dev request:', error.message);
    throw error;
  }
}

async function startApp() {
  if (process.env.NODE_ENV === 'development') {
    try {
      await devLogin();
      console.log('Development session established');
    } catch (error) {
      console.error('Failed to establish development session:', error);
    }
  }

  root.render(
    <StrictMode>
      <GoogleOAuthProvider clientId="89676155600-b024scjh0qd25mp96ce1mie6h9nqtdcb.apps.googleusercontent.com">
        <AgoraRTCProvider client={client}>
          <App />
        </AgoraRTCProvider>
      </GoogleOAuthProvider>
    </StrictMode>
  );
}

startApp();